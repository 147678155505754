<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>耗能管理</el-breadcrumb-item>
            <el-breadcrumb-item>省电助手</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>任务名称：</label>
                  <el-input class="width-220"  v-model="nameCode" placeholder="任务名称" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>状态：</label>
                  <el-button-group class="m-right-10">
                     <el-button :type="state === 'RUNNING' ? 'primary' : ''"  @click="state = 'RUNNING'"><span>运行</span></el-button>
                     <el-button :type="state === 'STOP' ? 'primary' : ''"  @click="state = 'STOP'"><span>停止</span></el-button>
                  </el-button-group>
               </el-row>
               <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-search"  @click="handleQuery(true)">
                  <span v-text="$t('msg.search')">搜索</span>
               </el-button>
               <el-button  class="m-bottom-5" type="primary" icon="el-icon-refresh-right" plain @click="handleQuery(false)">
                  <span v-text="$t('msg.reset')">重置</span>
               </el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <!-- 表格按钮 -->
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="m-bottom-5 bg-gradient" type="primary"  style="float: right" @click="handleCreate()">
                     <span>创建节省任务</span>
                  </el-button>
               </el-row>
            </div>
             <!-- 表格 -->
            <el-table
                     v-loading="loading"
                     :data="tableData"
                     :stripe="true"
                     border fit>
                  <el-table-column label="序号" width="60">
                     <template slot-scope="scope">
                        {{scope.$index + 1}}
                     </template>
                  </el-table-column>
                  <el-table-column prop="jobName" label="任务名称" min-width="170"></el-table-column>
                  <el-table-column prop="jobCode" label="任务编码" min-width="110"></el-table-column>
                  <el-table-column label="任务状态" min-width="80">
                     <template slot-scope="scope">
                        <el-switch
                              @change="handleChange(scope.row.jobId, scope.row.state)"
                              v-model="scope.row.state"
                              active-value="RUNNING"
                              inactive-value="STOP"
                              active-color="#67c23a"
                              inactive-color="#dcdfe6">
                        </el-switch>
                     </template>
                  </el-table-column>
                  <el-table-column prop="hotelName" label="所属酒店" min-width="160"></el-table-column>
                  <el-table-column prop="createTime" label="创建时间" min-width="150" sortable></el-table-column>
                  <el-table-column label="操作" min-width="200" fixed="right">
                     <template slot-scope="scope">
                        <!--<el-button type="text"  @click="handleDetail(scope.row.id)">
                           <el-link type="primary">详情</el-link>
                        </el-button>-->
                        <el-button type="text" @click="handleEdit(scope.row)" >
                           <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                        </el-button>
                        <el-button type="text" style="color: #F56C6C;" @click="handleDel(scope.row.id)" >
                           <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                        </el-button>
                     </template>
                  </el-table-column>
               </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
      </el-row>

      <!-- 添加/编辑弹窗 -->
      <el-dialog :title="`${title === 'add' ? '创建' : '编辑'}节省任务`"  :visible.sync="isShow" width="800px" :before-close="handleClose" v-if="isShow">
         <!-- 步骤条 -->
         <el-steps :space="600" :active="active" simple finish-status="success">
         <el-step title="设置条件"></el-step>
         <el-step title="设置任务"></el-step>
         </el-steps>
         <el-divider content-position="left"></el-divider>

         <!-- 第一步 设置条件 -->
         <template v-if="active === 1">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm mt20" label-position="right" label-width="200px" key="ruleForm">
               <el-form-item label="酒店名称:" prop="hotelId">
                  <el-select class="width-300" v-model="ruleForm.hotelId" disabled placeholder="请选择">
                  <el-option
                     v-for="item in hotelList"
                     :key="item.id"
                     :label="item.hotelName"
                     :value="item.id">
                  </el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="任务名称: " prop="jobName">
                  <el-input class="width-300" v-model="ruleForm.jobName" placeholder="请输入"></el-input>
               </el-form-item>
               <el-form-item label="选择条件: " prop="conditionType">
                  <el-select class="width-300" v-model="ruleForm.conditionType" placeholder="请选择" @change="changeConditionType">
                  <el-option
                     v-for="item in [{ label:'定时',value:'TIME'},{ label:'房态状态变化时',value:'ROOM_STATE'},{ label:'设备状态变化时',value:'DEVICE_STATE'},]"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                  </el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="执行次数: " prop="executions">
                  <el-select  class="width-300" v-model="ruleForm.executions" placeholder="请选择" @change="changeExecutions">
                  <el-option
                     v-for="item in [{ label:'仅执行一次',value:'one'},{ label:'自定义时间段',value:'timeSlot'},{ label:'不限次',value:'unTimes'},]"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                  </el-option>
                  </el-select>
               </el-form-item>

                  <!-- 选择条件-定时  执行次数-仅执行一次 -->
                  <el-form-item label="执行时间: " prop="time" v-if="ruleForm.conditionType === 'TIME' && ruleForm.executions === 'one'">
                  <el-date-picker class="width-300" v-model="ruleForm.time"  type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择时间" :picker-options="dataPickerOptions"></el-date-picker>
                  </el-form-item>
                  <!-- 执行次数-自定义时间段 -->
                  <el-form-item label="触发条件:" v-if="ruleForm.executions === 'timeSlot'">
                  <div class="block">
                     <el-date-picker class="width-300" v-model="ruleForm.timeSlot" start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期"
                                    type="daterange" value-format="yyyy-MM-dd" :picker-options="dataPickerOptions"></el-date-picker>
                  </div>
                  </el-form-item>
               <!-- 选择条件-定时  执行次数-自定义||不限次 -->
                  <template v-if="ruleForm.conditionType === 'TIME'">
                  <el-form-item label="执行时间: " prop="time" v-if="ruleForm.executions === 'timeSlot' || ruleForm.executions === 'unTimes'">
                     <el-time-picker class="width-300" v-model="ruleForm.time"  value-format="HH:mm:ss" placeholder="选择时间"
                                    :picker-options="{selectableRange: `${timePickerOptions} - 23:59:59`,format:'HH:mm:ss'}"></el-time-picker>
                  </el-form-item>
                  </template>

               <!-- 选择条件-房态状态变化时 -->
               <template v-if="ruleForm.conditionType === 'ROOM_STATE'">
                  <el-form-item label="房型状态: " prop="pmsState">
                  <el-select class="width-300" v-model="ruleForm.pmsState" placeholder="请选择" >
                     <el-option
                        v-for="item in [{ label:'入住',value:'CHECKIN'},{ label:'退房',value:'CHECKOUT'},{ label:'无人',value:'UNINHABITED'},]"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
                  </el-form-item>
                  <!-- 房型状态-无人 -->
                  <el-form-item label="红外感应器静默: " prop="delayedTime" v-if="ruleForm.pmsState === 'UNINHABITED'">
                  <el-input-number size="mini" v-model="ruleForm.delayedTime" :min="1" :max="1440" label="描述文字"></el-input-number>分钟，为无人状态。（时间范围为1~1440分钟）
                  </el-form-item>
               </template>
               <!-- 选择条件-设备状态变化时 -->
               <template v-if="ruleForm.conditionType === 'DEVICE_STATE'">
                  <el-form-item label="选择产品类别: " prop="productId">
                  <el-select  class="width-300" v-model="ruleForm.productId" placeholder="请选择" @change="changeProductId">
                     <el-option
                        v-for="(item,index) in [{ label:'取电开关',value:'HOSWZB1000010000'},{ label:'门磁',value:'HODSZB0100010000'},{ label:'红外人体感应器',value:'HOMSZB0100010000'},]"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
                  </el-form-item>
                  <el-form-item label="选择设备的触发动作: " prop="action">
                  <el-select  class="width-300" v-model="ruleForm.action" placeholder="请选择">
                     <!-- 选择产品类别-红外人体感应器-报警 -->
                     <template v-if="ruleForm.productId !== 'HOMSZB0100010000'">
                        <el-option
                        v-for="(item,index) in [{ value:'TURNON',label:'开'},{ value:'TURNOFF',label:'关'}]"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                     </template>
                     <el-option label="报警" value="INDUCTION" v-else></el-option>
                  </el-select>
                  </el-form-item>
               </template>
            </el-form>
         </template>

         <!-- 第二步 设置任务 -->
         <template v-else>
            <el-form :model="ruleFormSet" :rules="rulesSet" ref="ruleFormSet"  class="demo-ruleForm mt20" label-position="right" label-width="200px" key="ruleFormSet">
               <el-form-item label="选择房间:" prop="roomScope">
                  <el-radio-group v-model="ruleFormSet.roomScope" @change="handleRoomscope">
                  <el-radio-button label="ALL">所有房间</el-radio-button>
                  <el-radio-button label="ROOMTYPE">指定房型</el-radio-button>
                  <el-radio-button label="ROOM">指定房间</el-radio-button>
                  </el-radio-group>
               </el-form-item>
               <!-- 指定 房型 -->
               <el-form-item label="房型:" prop="roomTypeId" v-if="ruleFormSet.roomScope === 'ROOMTYPE'">
                  <el-select class="width-300" multiple filterable v-model="ruleFormSet.roomTypeId" placeholder="请选择" clearable>
                  <el-option
                     v-for="item in roomTypeList"
                     :key="item.id"
                     :label="item.roomTypeName"
                     :value="item.roomTypeId">
                  </el-option>
                  </el-select>
               </el-form-item>
               <!-- 指定 房间 -->
               <template v-if="ruleFormSet.roomScope === 'ROOM'">
                  <el-form-item label="楼栋/单元:" prop="buildUnitId">
                     <el-cascader class="width-300" v-model="ruleFormSet.buildUnitId" :options="ruleFormSet.buildUnitList" @change="changeBuildUnitList" clearable></el-cascader>
                  </el-form-item>
                  <el-form-item label="房间号:" prop="roomId">
                     <el-select class="width-300" v-model="ruleFormSet.roomId" multiple filterable placeholder="请选择">
                        <el-option
                           v-for="(item,index) in roomNoList"
                           :key="index"
                           :label="item.roomNo"
                           :value="item.id">
                        </el-option>
                     </el-select>
                  </el-form-item>
               </template>
               <el-form-item label="选择需要控制的设备: " prop="productOrDeviceId">
                  <el-select class="width-300" v-model="ruleFormSet.productOrDeviceId" multiple filterable placeholder="请选择"  @change="changeProductOrDevice">
                     <el-option
                        v-for="(item,index) in deviceList"
                        :key="index"
                        :label="item.versionsName"
                        :value="item.productId">
                     </el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="设置执行动作：">
                  <el-row class="content-box" style="padding: 0px;">
                     <el-row class="table-box">
                        <el-table border fit :data="ruleFormSet.devices" highlight-current-row style="width: 100%" >
                        <el-table-column label="产品类别" >
                           <template slot-scope="scope">
                              {{ scope.row.standard }}
                              {{ scope.row.productOrDeviceId.indexOf('HOSWZB0')!== -1 && scope.row.productOrDeviceId.substring(7,8) != 1 && scope.row.indexs+1  ? ` (${scope.row.indexs+1}键)` : ''  }}
                           </template>
                        </el-table-column>
                        <el-table-column prop="versionsName" label="设置动作" >
                           <template slot-scope="scope">
                              <el-form-item prop="validDevices" >
                              <el-select v-model="scope.row.action" placeholder="请选择">
                                 <el-option
                                    v-for="item in scope.row.productOrDeviceId === 'HOSLZB0100020000' ? [{ value: 'TURNON', label: '开'}] : [{ value: 'TURNON', label: '开'}, {value: 'TURNOFF',label: '关'}]"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                 </el-option>
                              </el-select>
                              </el-form-item>
                           </template>
                        </el-table-column>
                        </el-table>
                     </el-row>
                  </el-row>
               </el-form-item>
            </el-form>
         </template>

         <!-- 底部按钮 -->
         <span slot="footer" class="dialog-footer" v-if="active === 1">
            <el-button  @click="handleClose()">取消</el-button>
            <el-button type="primary" @click="handleNext('ruleForm')">下一步</el-button>
         </span>
         <span slot="footer" class="dialog-footer" v-else>
            <el-button  @click="goBack()">上一步</el-button>
            <el-button type="primary" @click="handleSave('ruleFormSet')">保存</el-button>
         </span>
      </el-dialog>
   </section>
</template>
<script>
   import { mapState } from 'vuex'
   import { electricity } from "@/api/interface/aiot";
   import { selectHotel } from '@/api/shop/goods/goods';
   import { axios_getBuildAndUnitList,axios_getRoomPage} from "@/api/aiot/project/houseManage";
   import { axios_addEditElectricity,axios_getElectricityDetail,axios_getDeviceListByRoomType,axios_getHotelRoomTypeList} from "@/api/aiot/project/batterySaver";
   import { setbuildCascader,getCron } from "@/common/js/common";
   export default {
      computed:{
         ...mapState(['hotelInfo'])
      },
      watch:{
         hotelInfo:{
            handler(newVal,oldVal){
               if(newVal.id !== oldVal.id && oldVal){
                  this.hotelId = this.hotelInfo.id
                  this.getElectricity();
               }
            }
         }
      },
      name: 'powerSaving',
      data() {
         const validProductOrDeviceId = (rule, value, callback) => {
            if (!this.ruleFormSet.productOrDeviceId.length) callback(new Error('请选择控制的设备！'))
            else callback()
         }
         const validDevices = (rule, value, callback) => {
            this.ruleFormSet.devices.forEach(item=>{
               if(item.action ==='') callback(new Error('请选择设置动作！'))
               else callback()
            })
         }
         return {
            // hotelData: [],   // 侧边栏数据
            tableData: [],   // 表格数据
            total: 0,        // 数据总数
            // sideLoad: true,  // 侧边栏加载效果
            loading: false,  // 表格加载效果
            // boxIcon: true,   // 侧边栏显示隐藏
            operWidth: 0,    // 编辑栏宽度
            /* 查询列表参数 */
            limit: 1,        // 每页数
            page: 1,         // 当前页
            hotelId: '',     // 酒店ID
            state: '',       // 状态
            nameCode: '',    // 名称/编码
            /* 提示文本 */
            prompt: '提示！',
            del_success: "删除成功！",
            confirm_remove: '确定移除？',
            confirm: '确定',
            cancel: '取消',
            select_success: '请先选中酒店！',
            change_success: '任务状态改变成功！',

            hotelList:[],       // 酒店id列表 下拉框
            isShow:false,      // 弹窗是否显示
            title:"add",       // 弹窗标题
            active:1,          // 步骤条

            dataPickerOptions: {
               disabledDate(time) {
                  return time.getTime() < Date.now() - 8.64e7;
               }
            },
            timePickerOptions:new Date(),
         // 第一步
            firstData:{},                // 第一步后台所需数据格式
            ruleForm:{
               hotelId: '',                // 酒店id
               conditionType: 'TIME',      // 选择条件  (定时 TIME、  房态状态变化时 ROOM_STATE、  设备状态变化时 DEVICE_STATE)
               timeSlot:[],                // 触发条件 开始结束时间  job中 startDate endDate
               delayedTime:1,              // 红外感应器静默时间 if(当pmsState=UNINHABITED时)

               // conditionParam 中的
               executions:'one',               // 执行次数
               // executions-one
               time:'',                        // 执行时间
               pmsState:'CHECKIN',             // 房型状态

               // job 中的
               jobName:'',                     // 任务名称
               // executions-unTimes
               cronExpression:'',              // 执行时间 时间表达式
               // conditionType-DEVICE_STATE
               productId:'HOSWZB1000010000',   // 选择产品类别
               action:"TURNON",                // 设备触发动作
            },
            rules:{
               hotelId:[{ required: true, message: '请输入酒店名称', trigger: 'blur' }],
               jobName:[{ required: true, message: '请输入任务名称', trigger: 'blur' }],
               conditionType:[{ required: true, message: '请选择条件', trigger: 'change' }],
               executions:[{ required: true, message: '请选择执行次数', trigger: 'change' }],
               time:[{ required: true, message: '请选择执行时间', trigger: 'change' }],
               // cronExpression:[{ required: true, message: '请选择执行时间', trigger: 'change' }],
               timeSlot:[{ required: true, message: '请选择触发条件', trigger: 'change' }],
               pmsState:[{ required: true, message: '请选择房型状态', trigger: 'change' }],
               delayedTime:[{ required: true, message: '请输入红外感应器静默时间', trigger: 'blur' }],
               productId:[{ required: true, message: '请选择产品类别', trigger: 'change' }],
               action:[{ required: true, message: '请选择设备触发动作', trigger: 'change' }],
            },
            cronPopover: false,         // 执行时间表达式弹窗是否显示
         // 第二步
            deviceList:[],              // 控制的设备 下拉数据
            roomTypeList:[],            // 房型 下拉数据
            roomNoList:[],              // 房间号

            ruleFormSet:{
               // rooms中
               roomScope: 'ALL',         // 选择房间  ALL 全部 ROOMTYPE 指定房型 ROOM 指定房间
               buildUnitId:[],           // 单元id
               // roomScope-ROOMTYPE
               roomTypeId:[],            // 房型
               // roomScope-ROOM
               roomId:[],                // 房号

               productOrDeviceId: [],     // 控制的设备
               devices:[],                // 执行动作 表格

               buildUnitList:[],          // 楼栋
            },
            rulesSet:{
               roomScope:[{ required: true, message: '请选择房间', trigger: 'change' }],
               buildUnitId:[{ required: true, message: '请选择楼栋/单元', trigger: 'change' }],
               productOrDeviceId:[{ required: true, validator: validProductOrDeviceId , trigger: 'change' }],
               validDevices:[{ required: true, validator: validDevices, trigger: 'change' }],
               roomTypeId:[{ required: true, message: '请选择房型', trigger: 'change' }],
               buildUnitList:[{ required: true, message: '请选择楼栋', trigger: 'change' }],
               roomId:[{ required: true, message: '请选择房号', trigger: 'change' }],
            },
            editId:'',                   // 编辑id
            jobId:'',                    // 编辑jobId

         };
      },
      beforeRouteEnter(to, from, next){
         if (from.name === 'createTasks'){
            next(vm => {
               vm.getElectricity()
            })
            return
         }
         next()
      },
      beforeRouteLeave(to, from, next){
         if (to.name === 'createTasks'){
            this.$store.dispatch('setCachePage', from.name)
            next()
            return
         }
         this.$store.dispatch('setCachePage', 'fakePage')
         next()
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.hotelId = this.hotelInfo.id
         this.getHotelOpt();
         this.getElectricity();
      },
      methods: {
         // 获取能耗列表
         getElectricity(){
            const url = electricity.electricity  + `?limit=${this.limit}&page=${this.page}`
            const param = {
               hotelId: this.hotelInfo.id,
               state: this.state,
               queryParam: this.nameCode,
            }
            this.$axios.post(url, param,'json').then(res => {
               if (res.success){
                  this.total = res.total
                  this.tableData = res.records
               }
            })
         },
         // 搜索任务
         handleQuery(bool){
            if (bool) return this.getElectricity()
            this.nameCode = ''
            this.state = ''
         },
         // 改变每页数
         changePageNum(num){
            this.limit = num
            this.getElectricity()
         },
         // 改变当前页
         changeCurrPage(num){
            this.page = num
            this.getElectricity()
         },

         // 创建任务
         handleCreate(){
            this.title = "add";
            this.isShow = true;
            this.ruleForm.hotelId = this.hotelInfo.id;
         },
         // 编辑任务
         handleEdit(row){
            this.title = "edit";
            // 获取详情信息
            axios_getElectricityDetail({ id:row.id}).then(res=>{
            if(res.success){
               // 回显处理
               let data = res.records
               this.editId = data.id
               this.jobId = data.jobId
               for (const k in this.ruleForm) k in data && (this.ruleForm[k] = data[k])
               if(data.conditionType === 'INTO_THE_ROOM_CARD') this.ruleForm.conditionType = 'DEVICE_STATE'
               if(data.conditionType === 'DOOR') this.ruleForm.conditionType = 'DEVICE_STATE'

               if(data.hasOwnProperty('job')){
                  for (const k in this.ruleForm) k in data.job && (this.ruleForm[k] = data.job[k])
               }
               if(data.hasOwnProperty('conditionParam')) {
                  let conditionParam = JSON.parse(data.conditionParam)
                  for (const k in this.ruleForm) k in conditionParam && (this.ruleForm[k] = conditionParam[k])
                  this.ruleForm.timeSlot = [conditionParam.startDate,conditionParam.endDate ] // 自定义时间
               }
               if(data.hasOwnProperty('devicesDTO')){
                  data.devicesDTO.forEach((item,index)=>{
                  this.ruleFormSet.devices.push({
                     action:item.action,                        //  关-TURNOFF 开-TURNON 默认关
                     deviceScope:item.deviceScope,              // 固定值
                     productOrDeviceId:item.productOrDeviceId,  // 产品设备id
                     standard:item.standard,                    // 设备名称
                  })
                  if(item.hasOwnProperty('indexs')) this.ruleFormSet.devices[index].indexs = item.indexs
                  this.ruleFormSet.productOrDeviceId.push(item.productOrDeviceId)
                  })
                  // 去重
                  this.ruleFormSet.productOrDeviceId = this.ruleFormSet.productOrDeviceId.reduce((accumulator,currentValue)=>{
                  if(!accumulator.includes(currentValue)) return accumulator.concat(currentValue) // 累计值查找不到当前值的话,当前值拼接到累计值中
                  else return accumulator
                  },[])
               }
               if(data.hasOwnProperty('rooms')){
                  if(data.rooms.length !== 0){
                  this.ruleFormSet.roomScope = data.rooms[0].roomScope
                  this.ruleFormSet.buildUnitId = [data.rooms[0].buildId,data.rooms[0].buildUnitId]
                  this.getRoomPage('edit',data.rooms)
                  }
               }
               this.active = 1;      // 打开默认第一项
               this.isShow = true;   // 显示
            }
            })
         },

         //保存 创建/编辑
         handleSave(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
               this.firstData.devices = this.ruleFormSet.devices
               this.firstData.rooms = []
               // 选择房间-全部
               if(this.ruleFormSet.roomScope === 'ALL'){
                  this.firstData.rooms = [{
                  roomScope:this.ruleFormSet.roomScope,
                  }]
               }
               // 选择房间-房型
               if(this.ruleFormSet.roomScope === 'ROOMTYPE') {
                  this.ruleFormSet.roomTypeId.forEach(item=>{
                  this.firstData.rooms.push({
                     roomScope:this.ruleFormSet.roomScope,
                     roomOrTypeId:item
                  })
                  })
               }
               // 选择房间-房间
               if(this.ruleFormSet.roomScope === 'ROOM'){
                  this.ruleFormSet.roomId.forEach((item,index)=>{
                  this.firstData.rooms.push({
                     roomScope:this.ruleFormSet.roomScope,
                     roomOrTypeId:item
                  })
                  // if((typeof this.ruleFormSet.buildUnitId) === 'object') this.firstData.rooms[index].buildUnitId = this.ruleFormSet.buildUnitId[1]
                  // else {
                     this.firstData.rooms[index].buildId = this.ruleFormSet.buildUnitId[0]
                     this.firstData.rooms[index].buildUnitId = this.ruleFormSet.buildUnitId[1]
                  // }
                  })
               }
               if(this.title === 'edit'){
                  this.firstData.id = this.editId
                  this.firstData.job.id = this.jobId
                  this.firstData.rooms.forEach(item=>{ item.elecId = this.editId })
               }
               axios_addEditElectricity(this.firstData,this.title).then(res=>{
                  if( res.success ){
                     this.handleClose()
                     this.$message({
                        showClose: true,
                        message: '保存成功',
                        type: 'success'
                     })
                     this.getElectricity()
                  }
               })
            } else {
               console.log('error submit!!');
               return false;
            }
            })
         },

         // 删除任务
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               const url = electricity.delElectricity + `/${id}`
               this.$axios.post(url, {}, 'json').then(res => {
                  if (res.success){
                     this.$message({
                        showClose: true,
                        message: this.del_success,
                        type: 'success'
                     })
                     this.getElectricity()
                  }
               })
            }).catch(cancel => {
               console.log(cancel)
            })
         },
         // 改变任务状态
         handleChange(jobId,state){
            const url = electricity.setJobState
            const params = {
               jobId,
               state,
            }
            this.$axios.post(url, params).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.change_success,
                     type: 'success'
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 查看详情
         // handleDetail(){}

         /* 第一步处理*/
         // 下一步
         handleNext(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
               this.firstData = {
                  hotelId:this.ruleForm.hotelId,            // 酒店id
                  conditionType:this.ruleForm.conditionType,// 选择条件
                  conditionParam:{ // 必须转为JSON字符
                  executions:this.ruleForm.executions
                  },
                  job:{
                  jobName:this.ruleForm.jobName,            // 任务名称
                  jobClassName:this.ruleForm.conditionType, // 跟随conditionType
                  state:"RUNNING",                          // 任务状态 运行,固定值
                  jobGroup:'0',
                  // action: "",
                  // productId: "",
                  // cronExpression:'* * * * * ? *'
                  },
               }
               // 选择条件-定时
               if(this.ruleForm.conditionType === 'TIME'){
                  this.firstData.job.jobGroup = '0'
                  this.firstData.conditionParam.time = this.ruleForm.time
                  // 执行次数executions-仅一次/自定义时间/不限次
                  if(this.ruleForm.executions === 'one') this.firstData.job.cronExpression = getCron(this.ruleForm.executions,this.ruleForm.time)
                  if(this.ruleForm.executions === 'timeSlot') this.firstData.job.cronExpression = getCron(this.ruleForm.executions,[this.ruleForm.timeSlot[0],this.ruleForm.timeSlot[1],this.ruleForm.time])
                  if(this.ruleForm.executions === 'unTimes') this.firstData.job.cronExpression = getCron(this.ruleForm.executions,this.ruleForm.time)
               }
               // 选择条件-房态状态变化时
               else if(this.ruleForm.conditionType === 'ROOM_STATE'){
                  this.firstData.job.jobGroup = '1'
                  this.firstData.conditionParam.pmsState = this.ruleForm.pmsState
                  // 房型状态-无人
                  if(this.ruleForm.pmsState === 'UNINHABITED') this.firstData.delayedTime = this.ruleForm.delayedTime
               }
               // 选择条件-设备状态发生变化时
               else if(this.ruleForm.conditionType === 'DEVICE_STATE'){
                  this.firstData.job.jobGroup = '2'
                  this.firstData.conditionParam.productId = this.ruleForm.productId // 产品类别
                  this.firstData.conditionParam.action = this.ruleForm.action       // 设备触发动作
                  // 选择产品类别 = 取电开关
                  if(this.ruleForm.productId === 'HOSWZB1000010000') this.firstData.conditionType = 'INTO_THE_ROOM_CARD'
                  // 选择产品类别 = 门磁
                  if(this.ruleForm.productId === 'HODSZB0100010000') this.firstData.conditionType = 'DOOR'
               }
                  // 执行次数-仅执行一次
               // if(this.ruleForm.executions === 'one') {}
                  // 执行次数-不限次
                  // else if(this.ruleForm.executions === 'unTimes') {}
               // 执行次数-自定义时间
               if(this.ruleForm.executions === 'timeSlot') {
                  let time = ''
                  if(this.ruleForm.timeSlot[0].indexOf('00:00:00') === -1) time = ' 00:00:00'
                  else time = ''
                  this.firstData.job.startDate =  this.ruleForm.timeSlot[0] + time
                  this.firstData.job.endDate =  this.ruleForm.timeSlot[1] +  time
                  this.firstData.conditionParam.startDate = this.firstData.job.startDate
                  this.firstData.conditionParam.endDate = this.firstData.job.endDate
               }
               this.firstData.conditionParam = JSON.stringify(this.firstData.conditionParam)
               this.active = 2;  // 下一步

               this.getDeviceListByRoomType()
               // 选择房间-指定房型
               if( this.ruleFormSet.roomScope === 'ROOMTYPE') this.getHotelRoomTypeList()
               // 选择房间-指定房间
               else if(this.ruleFormSet.roomScope === 'ROOM') this.getBuildUnitId()
            } else {
               console.log('error submit!!');
               return false;
            }
            })
         },

         /* 第一步处理*/
         // 获取 执行时间表达式
         // changeCron(val){
         //    this.ruleForm.cronExpression = val
         //    this.ruleForm.time = cronChangeDate(val).loopTime
         // },
         // 选择条件
         changeConditionType(){
            // if(this.ruleForm.conditionType === 'TIME') ROOM_STATE DEVICE_STATE
            this.changeExecutions()
         },
         // 执行次数
         changeExecutions(){
            this.ruleForm.time = ''
            this.ruleForm.timeSlot = []
         },

         // 产品类别 变化时
         changeProductId(){
            // 产品类别为红外人体感应器时  设备触发动作-报警
            if(this.ruleForm.productId === 'HOMSZB0100010000') return this.ruleForm.action = 'INDUCTION'
            this.ruleForm.action = 'TURNON'
         },

         // 楼栋/单元发生变化时
         changeBuildUnitList(){
            this.ruleFormSet.roomId = []
            this.getRoomPage()
         },

         // 获取酒店下拉
         getHotelOpt() {
            selectHotel({}).then(({ success, records }) => {
            if (success) this.hotelList = records.map(item => ({ id: item.id, hotelName: item.hotelName }))
            })
         },

         /* 第二步处理*/
         // 获取 楼栋/单元 下拉框
         getBuildUnitId(){
            axios_getBuildAndUnitList(this.ruleForm.hotelId).then(res=> {
              if (res.success) {
                this.ruleFormSet.buildUnitList = setbuildCascader(res.records);
                // this.getRoomPage();
              }
            })
         },

         // 获取房间号
          getRoomPage(state,roomData){
            let params = { limit: 99, page: 1 }
            let data = {
              hotelId:this.ruleForm.hotelId,
              buildUnitId:this.ruleFormSet.buildUnitId.length ? this.ruleFormSet.buildUnitId[1] : '' ,
            }
            axios_getRoomPage(params,data).then( res =>{
              if(res.success) {
                this.roomNoList = res.records;
                if(state === 'edit') {
                  roomData.forEach(item=>{
                    if(this.ruleFormSet.roomScope === 'ROOMTYPE') this.ruleFormSet.roomTypeId.push(item.roomOrTypeId)
                    if(this.ruleFormSet.roomScope === 'ROOM') this.ruleFormSet.roomId.push(item.roomOrTypeId)
                  })
                }
              }
            })
          },

         // 选择房间 发生变化时
         handleRoomscope(){
            // 选择房间-指定房型
            if(this.ruleFormSet.roomScope === 'ROOMTYPE') this.getHotelRoomTypeList()
            // 选择房间-指定房间
            else if(this.ruleFormSet.roomScope === 'ROOM') this.getBuildUnitId()
         },
         // 获取 控制的设备 下拉框
         getDeviceListByRoomType(){
            let data = { hotelId:this.ruleForm.hotelId , roomScope:"ALL" }
            axios_getDeviceListByRoomType(data).then(res=>{
              if(res.success) this.deviceList = res.records
            })
         },
         // 获取 房间类型 下拉框
         getHotelRoomTypeList(){
            axios_getHotelRoomTypeList({hotelId:this.ruleForm.hotelId}).then(res=> {
              if (res.success) this.roomTypeList =  res.records
            })
         },
         // 选择设备
         changeProductOrDevice(){
            this.ruleFormSet.devices = []
            this.deviceList.forEach(item=>{
            this.ruleFormSet.productOrDeviceId.forEach(items=>{
               if(item.productId === items){
                  // 当为开关面板时
                  if(item.productId.indexOf('HOSWZB0') !== -1){
                  let numbers = Number(item.productId.substring(7,8)) // 第八位判断此为几位开关面板
                  for(var i = 0 ; i < numbers ; i++) {
                     this.ruleFormSet.devices.push({
                        action:"TURNON",                 //  关-TURNOFF 开-TURNON 默认开
                        deviceScope:"PRODUCT",            // 固定值
                        indexs:i,
                        productOrDeviceId:item.productId, // 产品设备id
                        standard:item.versionsName,       // 设备名称 对了你知道vhp吗
                     })
                  }
                  }else{
                  this.ruleFormSet.devices.push({
                     action:"TURNON",                 //  关-TURNOFF 开-TURNON 默认开
                     deviceScope:"PRODUCT",            // 固定值
                     productOrDeviceId:item.productId, // 产品设备id
                     standard:item.versionsName,       // 设备名称
                  })
                  }
               }
            })
            })
         },


         // 上一步
         goBack(){ this.active = 1; },

         // 关闭弹窗
         handleClose(){
            this.isShow = false
            this.resetForm()
         },
         // 弹窗重置
         resetForm(){
            for (const k in this.ruleForm) this.ruleForm[k] = ''
            this.ruleForm.conditionType = 'TIME'            // 选择条件
            this.ruleForm.executions = 'one'                // 执行次数
            this.ruleForm.pmsState = 'CHECKIN'              // 房型状态
            this.ruleForm.productId = 'HOSWZB1000010000'    // 选择产品类别
            this.ruleForm.action = 'TURNON'                // 设备触发动作
            this.ruleForm.timeSlot = []                     // 触发条件

            for (const k in this.ruleFormSet) this.ruleFormSet[k] = []
            this.ruleFormSet.roomScope = 'ALL'                        // 选择房间
            this.ruleFormSet.buildUnitId = []
            this.active = 1
         },
      },
   };
</script>
